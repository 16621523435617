var $ = require('jquery');

require('./modules/webfont-loader');
require('./modules/map');
require('./modules/main-navigation');
require('./modules/owl-carousel');
require('./modules/product-filters');
require('./modules/select-change');
require('./modules/tabs');
require('./modules/woocommerce');

document.getElementsByClassName('no-js')[0].classList.remove('no-js');

document.addEventListener("contextmenu", function(e){
    e.preventDefault();
}, false);