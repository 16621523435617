require('owl.carousel');

(function ($) {
    $('.js-hero-carousel').on('initialized.owl.carousel translated.owl.carousel', function (property) {
        var activeSlide = $('.js-hero-carousel .owl-item.active .banner').data('slide-count');

        $('.js-dot').removeClass('active');
        $('.js-dot[data-slide-target="' + activeSlide + '"]').addClass('active');
    });

    $('.js-hero-carousel').owlCarousel({
        dots: false,
        items: 1,
        loop: true,
        mouseDrag: true,
        nav: true,
        touchDrag: true
    });

    $('.js-dot').on('click', function (e) {
        e.preventDefault();

        $('.js-hero-carousel').trigger('to.owl.carousel', [$(this).index(), 300]);
    });

    var quotesCarouselItems = 1,
        hasQuotesCarouselItems = $('.js-quotes-carousel .quote').length > quotesCarouselItems;

    $('.js-quotes-carousel').owlCarousel({
        autoHeight: true,
        dots: false,
        items: 1,
        loop: hasQuotesCarouselItems,
        mouseDrag: hasQuotesCarouselItems,
        nav: hasQuotesCarouselItems,
        touchDrag: hasQuotesCarouselItems
    });

    var productCarouselItems = 1,
        hasProductCarouselItems = $('.js-product-carousel .woocommerce-product-gallery__image').length > productCarouselItems;

    $('.js-product-carousel').owlCarousel({
        dots: false,
        items: 1,
        loop: true,
        mouseDrag: hasProductCarouselItems,
        nav: hasProductCarouselItems,
        navText: [''],
        touchDrag: hasProductCarouselItems
    });

    $('.woocommerce-product-gallery__thumbnails .woocommerce-product-gallery__image').on('click', function (e) {
        e.preventDefault();

        $('.js-product-carousel').trigger('to.owl.carousel', [$(this).index(), 300]);
    });
}(jQuery));