(function ($) {
    function initMap() {
        var templateUrl = theme_path.templateUrl;
        var mapSelector = $('.js-map');
        var mapLocation = {
            'lat': Number(mapSelector.attr('data-lat')),
            'lng': Number(mapSelector.attr('data-lng'))
        };

        var mapElement = new google.maps.Map(mapSelector[0], {
            mapTypeControl: false,
            zoom: 16,
            center: {
                lat: mapLocation.lat,
                lng: mapLocation.lng
            },
            scrollwheel: false,
            disableDefaultUI: true
        });

        var marker = new google.maps.Marker({
            position: {
                lat: mapLocation.lat, lng: mapLocation.lng
            },
            map: mapElement
        });

        var getMapCenter = mapElement.getCenter();

        google.maps.event.addDomListener(window, 'resize', function () {
            mapElement.setCenter(getMapCenter);
        });
    }

    if ($('.js-map').length >= 1) {
        $(window).on('load', initMap);
    }
})(jQuery);