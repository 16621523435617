(function ($) {
    function changeProductGalleryImage(image_id) {
        if (image_id >= 1) {
            $('.woocommerce-product-gallery__thumbnails [data-image-id="' + image_id + '"]').trigger('click');
        } else {
            $('.woocommerce-product-gallery__image:first-child').trigger('click');
        }
    }

    $(document.body).on('show_variation', '.single_variation_wrap', function (event, variation) {
        changeProductGalleryImage(variation.image_id);
    });

    $(document.body).on('hide_variation', '.single_variation_wrap', function () {
        changeProductGalleryImage(false);
    });
}(jQuery));
