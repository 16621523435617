(function ($) {
    $('.main-navigation').on('click', '.menu-item-has-children', function (e) {
        e.preventDefault();

        $('.menu-item-has-children.open').not($(this)).each(function () {
            $(this).removeClass('open')
        });

        $(this).toggleClass('open');
    });

    $('.sub-menu .menu-item').on('click', 'a', function (e) {
        e.stopPropagation();
    });

    $('.mobile-navigation').on('click', '.menu-item-has-children', function (e) {
        e.preventDefault();

        $(this).toggleClass('open');
    });

    $('.js-navigation-toggle').on('click', function (e) {
        e.preventDefault();

        // Reset the scroll position of the menu
        $('.menu-item-has-children.open').removeClass('open');

        $('.js-navigation-target').toggleClass('site-header--open');
    });
})(jQuery);