(function ($) {
    $('.js-product-filter input').on('change', function () {
        var parentElement = $(this).parents('.js-product-filter');

        if (parentElement.find('.js-product-filter-update').is(':hidden')) {
            parentElement.find('.js-product-filter-update').trigger('click');
        }
    });

    $('.js-filter-tab').on('click', function (e) {
        e.preventDefault();

        $('.c-product-filters').toggleClass('c-product-filters--active');
    });
})(jQuery);
