(function ($) {
    var tabSelector = $('.js-tab-selector');
    if (tabSelector.length < 1) return;

    var animationDuration = 300;

    tabSelector.on('click', function (e) {
        e.preventDefault();

        var openTab = $(this).attr('href');

        tabSelector.removeClass('tabs__selector--active');
        $(this).addClass('tabs__selector--active');
        $('.tabs__content').removeClass('tabs__content--active');
        $(openTab).addClass('tabs__content--active');

        if ($('.tabs__content--mobile').is(':visible')) {
            $('html, body').animate({
                scrollTop: $(this).offset().top
            }, animationDuration);
        }
    });
})(jQuery);